import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { AuthService } from '@shared/services/auth.service'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class DashboardGuard {
  constructor(
    private auth: AuthService,
    private router: Router,
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.auth.isAuthenticated()) {
      if (this.auth.isNewCustomer) {
        if (this.auth.isSetupRequired()) {
          return this.router.parseUrl('/dashboard-visa/setup')
        } else {
          return true
        }
      } else {
        return this.router.parseUrl('/dashboard/home')
      }
    }
    return this.router.parseUrl('/dashboard-visa/login')
  }
}
